<div class="homepageContainer">
    <div class="showcaseBlock">
        <p class="header"><span class="italic">VERSION</span><br>E X C L U S I V E</p>
        <p class="description">
            La marque est née en 2019 d'une passion dévorante de longue date pour l'automobile.
            Avec l'ambition de lancer la première de jantes au design absolument suisse,
            son nom s'est imposé comme une évidence: la contraction de SWISS WHEELS.
        </p>
        <p class="description">
            Un des gènes fondateurs de SWHEELS était de privilégier la personnalisation;
            pouvoir créer une version unique, exclusive.
        </p>
        <p class="description">
            Bon nombre d'étapes ont été franchies depuis; pour preuve, vous regardez le premier site,
            qui contient au moins 6 modèles de jantes.
        </p>
    </div>

    <div class="showcaseBlock">
        <p class="header"><span class="italic">DESIGN</span><br>S I N G U L I E R</p>
        <p class="description">
            Les modèles SWHEELS sont le résultat d'une démarche plutôt non-confrmiste.
            Ils ont été imaginés de toute pièce par un graphic designer, totalement étrangé
            à l'univers automobile et ses codes.
        </p>
        <p class="description">
            Les influences et inspirations pour le moins éclectiques confèrent à notre marque
            un style tout à fait singulier
        </p>
    </div>

    <div class="showcaseBlock">
        <p class="header"><span class="italic">PALETTE</span><br>D É R O U T A N T E</p>
        <p class="description">
            Swheels met à votre disposition une palette de plus de 50 couleurs,
            ainsi qu'une multitude d'autres finitions; au total, plus de 2'500 combinaisons
            possibles. Assurément de quoi satisfaire voes envies et votre style.
        </p>
        <p class="description">
            Evidemment, un conseil d'expert avisé pourra vous aider à faire votre choix
        </p>
    </div>
</div>
