<div>
    <div class="wheelContainer" *ngFor=" let model of models; index as i" id="{{model.name}}">
        <!-- name of the model, with black text and red text separated -->
        <!-- container for 2d img -->
        <!-- grid of 3x3 square nodes -->
        <div class="contentContainer">
          <div class="imgContainer">
            <img id="{{model.name}}_bigImg" width="100%" src="assets/img/models/{{model.name}}/{{model.big_img}}.png">
          </div>
            <div class="descriptionContainer">
              <div class="titleContainer">
                {{model.blackPart}}<span class="redText">{{model.redPart}}</span>
              </div>
              <div class="description">

                    <p>Swheels forge ces jantes à partir d'alluminium de qualité supérieure 6061-T6</p>
                    <h3><span class="redText">Description</span></h3>
                    <p>
                        Construction: Monoblock | 2 parties | 3 parties <br/>
                        Profile: Concave | Deep Concave<br/>
                        Cache central: Aluminium forgé Pentagonal<br/>
                        Diamètre: 18'' |19'' |20'' |21'' |22'' |23'' |<br/>
                        Largeur: 8.0'' - 13.0''<br/>
                        Déport: sur mesure<br/>
                        Couleur et gravure: personnalisable<br/>
                        Garantie: 5 ans
                    </p>
                </div>
            </div>
            <div class="smImgcontainer">
                <img *ngFor=" let img of model.small_img; index as j" class="wheelIcon" src="assets/img/models/{{model.name}}/{{model.small_img[j]}}_sm.png" (click)="updateImg($event,j,i)">
            </div>
        </div>
    </div>
</div>
