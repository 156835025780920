import {Component} from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'swheels';
    isMenuDisplayed = false;
    displayMenu = 'menu-none';

    onMenuClick() {
        this.isMenuDisplayed = !this.isMenuDisplayed;
        this.displayMenu = (this.isMenuDisplayed) ? 'menu' : 'menu-none';
    }
}
