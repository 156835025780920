<!--The content below is only a placeholder and can be replaced.-->
<!-- -->
<base href="/">

<nav class="navbar navbar-expand-lg">
  <div class="responsiveContainer">
    <img class="menuHamburger" src="assets/img/Hamburger_icon.png" (click)="onMenuClick()"/>
    <div
      [ngClass]="displayMenu">
      <a class="navbar-brand" routerLink="/" routerLinkActive="active">
        <img class="menuIcon" src="assets/img/logo_s.png"/>
        <span>Models</span>
      </a>
      <a class="navbar-brand" routerLink="/gallery" routerLinkActive="active">
        <img class="menuIcon" src="assets/img/logo_s.png"/>
        <span>Gallery</span>
      </a>
      <!--<a class="navbar-brand" routerLink="/contact" routerLinkActive="active">
        <img class="menuIcon" src="assets/img/logo_s.png"/>
        <span>About Us</span>
      </a> -->
    </div>
  </div>
  <a class="banner" routerLink="/welcome" routerLinkActive="active">
    <img class="banner" src="assets/img/logo.png"/>
  </a>
</nav>


<router-outlet></router-outlet>
<div class="footer">
  <span class="rs">Retrouvez nous sur:</span>
  <div class="socialMediaContainer">
    <a href="https://www.instagram.com/bellecroixcars/">
      <img src="assets/img/social_media/Instagram.png">
    </a>
    <span style="width: 54px"></span>
    <a href="https://www.facebook.com/bellecroixcars/">
      <img src="assets/img/social_media/Facebook.png" height="72px">
    </a>
  </div>
  <div class="spacing"></div>
  <div class="socialMediaContainer">
    <img class="banner" src="assets/img/logo.png">
    <span class="rs">© Belle-Croix Cars 2018-21</span>
  </div>
</div>
