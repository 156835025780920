import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WheelsComponent} from './wheels/wheels.component';
import {HomepageComponent} from './homepage/homepage.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {GalleryComponent} from './gallery/gallery.component';

const routes: Routes = [
  {path: '', component: WheelsComponent},
  {path: 'welcome', component: HomepageComponent},
  {path: 'contact', component: AboutUsComponent},
  {path: 'gallery', component: GalleryComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
