import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  // public static BASEURL = 'http://192.168.1.216/rest/public'; // DEV
  public static BASEURL = 'https://carsandcafe.ch/rest/public'; // PROD
  constructor(private httpClient: HttpClient) {
  }

  public getGalleryLinks(): Promise<JSON> {
    return new Promise(resolve => {
      this.httpClient.get(HttpClientService.BASEURL + '/getWheelsGallery').subscribe(data => {
        resolve(data as JSON);
      });
    });
  }
}
