<div class="contactContainer">
  <p class="header"> Contact infos: </p>
</div>
<div class="socialMediaContainer">
  <div class="media">

  </div>
  <div class="media">

  </div>
</div>
