import { Component, OnInit } from '@angular/core';
import {HttpClientService} from '../services/http-client.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  public imgLinks: JSON;

  constructor(public service: HttpClientService) {
    this.service.getGalleryLinks().then(data => {
      this.imgLinks = data;
      console.log(this.imgLinks);
    });
  }

  ngOnInit(): void {
  }

}
