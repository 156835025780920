import {Component, OnInit} from '@angular/core';
// @ts-ignore
import data from './wheelsModels.json'; // json array for wheels models

@Component({
  selector: 'app-wheels',
  templateUrl: './wheels.component.html',
  styleUrls: ['./wheels.component.scss']
})
export class WheelsComponent implements OnInit {
  models: any[];

  constructor() {
    // workaround because *ngfor could not find data reference
    this.models = data.models;
    for (let model of this.models) {
      model.big_img = '3quarter';
      model.small_img = ['face', 'concave', 'filaire_black'];
    }
  }

  ngOnInit() {
  }


  updateImg(event, imgNb: number, wheelNb: number) {
    const temp = this.models[wheelNb].big_img;
    this.models[wheelNb].big_img = this.models[wheelNb].small_img[imgNb];
    this.models[wheelNb].small_img[imgNb] = temp;
    console.log(temp);
  }

}
